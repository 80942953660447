
/*
 * List of events that DataTables can fire, so they can re-exported to React
 */
export default [
	'autoFill',
	'preAutoFill',
	'buttons-action',
	'buttons-processing',
	'column-reorder',
	'columns-reordered',
	'childRow',
	'column-sizing',
	'column-visibility',
	'destroy',
	'draw',
	'dt-error',
	'info',
	'init',
	'length',
	'options',
	'order',
	'page',
	'preDraw',
	'preInit',
	'preXhr',
	'processing',
	'requestChild',
	'search',
	'stateLoaded',
	'stateLoadParams',
	'stateSaveParams',
	'xhr',
	'key',
	'key-blur',
	'key-focus',
	'key-prefocus',
	'key-refocus',
	'key-return-submit',
	'responsive-display',
	'responsive-resize',
	'rowgroup-datasrc',
	'pre-row-reorder',
	'row-reorder',
	'row-reorder-canceled',
	'row-reorder-changed',
	'row-reordered',
	'dtsb-inserted',
	'deselect',
	'select',
	'select-blur',
	'selectItems',
	'selectStyle',
	'user-select',
	'stateRestore-change',
];
